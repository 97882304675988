<template>
    <div>
        <vs-button
            v-on:click="selectNewFile"
            icon-pack="feather"
            icon="icon-paperclip"
            :color="colour || 'primary'"
        >
            <span v-html="btnText"></span>
        </vs-button>
        <input
            id="file-input"
            ref="fileInput"
            type="file"
            @change="handleFileChange"
            v-bind:accept="acceptFiles"
        />
        <label for="file-input">{{ fileDescription }}</label>
    </div>
</template>

<script>
export default {
    name: 'BtnFileSelect',
    props: ['file', 'acceptFiles', 'colour'],
    computed: {
        btnText() {
            if (
                this.file &&
                (this.file instanceof File || typeof this.file === 'string')
            )
                return 'Change File'
            return 'Add File'
        },
        fileDescription() {
            if (this.file && this.file instanceof File) {
                return `${this.file.name} ${(this.file.size / 1000000).toFixed(
                    3
                )} Mb`
            }
            return null
        },
    },
    methods: {
        handleFileChange(e) {
            this.$emit('fileChanged', e.target.files[0])
            e.target.value = ''
        },
        selectNewFile() {
            this.$refs.fileInput.click()
        },
    },
}
</script>

<style scoped>
input[type='file'] {
    display: none;
}
.file-size {
    font-size: 0.7em;
}
</style>
