var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vx-card',{attrs:{"title":"Quick Codes","collapse-action":""}},[_c('vs-table',{staticClass:"mb-base",attrs:{"data":_vm.data,"stripe":""},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return _vm._l((data),function(tr,index){return _c('vs-tr',{key:index},[_c('vs-td',[_vm._v(" "+_vm._s(tr.code)+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(tr.discount)+" ")]),_c('vs-td',[_vm._v(" Email - "),(tr.site_template_email)?_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$router.push({
                                name: 'Email',
                                params: { pk: tr.site_template_email.id },
                            })}}},[_vm._v(_vm._s(tr.site_template_email.description))]):(data.email_template)?_c('span'):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" Sms - "),(tr.site_template_sms)?_c('span',{staticClass:"link",on:{"click":function($event){return _vm.$router.push({
                                name: 'Text',
                                params: { pk: tr.site_template_sms.id },
                            })}}},[_vm._v(_vm._s(tr.site_template_sms.description))]):(data.sms_template)?_c('span'):_vm._e()]),_c('vs-td',[_vm._v(" "+_vm._s(tr.used_count)+" ")])],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" Code ")]),_c('vs-th',[_vm._v(" Discount ")]),_c('vs-th',[_vm._v(" Templates ")]),_c('vs-th',[_vm._v(" Used Count ")])],1)],2),(_vm.data.number_of_pages > 1)?_c('vs-pagination',{attrs:{"total":_vm.data.number_of_pages},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }