<template>
    <div>
        <div class="flex flex-row-reverse w-full">
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item
                        v-on:click="
                            $router.push({
                                name: 'brokerEdit',
                                params: { pk: data.email },
                            })
                        "
                    >
                        Edit Account
                    </vs-dropdown-item>
                    <vs-dropdown-item
                        v-on:click="confirmAgreementDDEmail"
                        divider
                    >
                        Send Agreement & DD
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="confirmAgreementEmail">
                        Send Agreement
                    </vs-dropdown-item>
                    <vs-dropdown-item v-on:click="confirmDDEmail">
                        Send Direct Debit
                    </vs-dropdown-item>

                    <vs-dropdown-item divider disabled>
                        Delete Broker
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <vx-card title="Account">
            <!-- Avatar -->
            <div class="vx-row">
                <!-- Avatar Col -->
                <div
                    class="vx-col w-full sm:w-full md:w-1/2 lg:w-2/6 xl:w-1/6 mb-base"
                    id="avatar-col"
                >
                    <div class="img-container mb-4">
                        <img :src="data.logo" class="rounded w-full" />
                    </div>
                </div>

                <!-- Information - Col 1 -->
                <div class="vx-col flex-1">
                    <table>
                        <tr class="flex">
                            <td class="font-semibold">Company Name</td>
                            <td>
                                <div class="flex">
                                    <span class="mr-6">{{
                                        data.company_name
                                    }}</span>
                                    <vs-chip
                                        v-if="data.active"
                                        color="success"
                                        class="ml-auto"
                                        >Account Active</vs-chip
                                    >
                                    <vs-chip
                                        v-if="!data.active"
                                        color="secondary"
                                        class="ml-auto"
                                        >Account Disbaled
                                    </vs-chip>
                                </div>
                            </td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold">Email</td>
                            <td>{{ data.email }}</td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold">Contact Person</td>
                            <td>{{ data.contact_name }}</td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold self-center">
                                User Account
                            </td>
                            <td class="self-center">
                                <span v-if="data.user">{{
                                    data.user.details.full_name ||
                                    data.user.email
                                }}</span>
                                <span v-else>Not linked</span>
                            </td>
                            <td class="self-center" v-if="data.user">
                                <vs-button
                                    v-on:click="
                                        $router.push({
                                            name: 'user',
                                            params: { id: data.user.id },
                                        })
                                    "
                                    radius
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-chevron-right"
                                    :disabled="!data.user"
                                />
                            </td>
                        </tr>

                        <tr class="flex">
                            <td class="font-semibold">Primis ID</td>
                            <td>
                                {{ data.primis_id }}
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- /Information - Col 1 -->

                <!-- Information - Col 2 -->
                <div class="vx-col flex-1" id="account-info-col-2">
                    <table>
                        <tr>
                            <td class="font-semibold"></td>
                            <td></td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold">Address</td>
                            <td>{{ data.address_line_1 }}</td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold"></td>
                            <td>{{ data.address_line_2 }}</td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold"></td>
                            <td>{{ data.post_code }}</td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold">Phone</td>
                            <td>
                                {{ data.contact_number }}
                            </td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold"></td>
                            <td>
                                {{ data.mobile_number }}
                            </td>
                        </tr>
                        <tr class="flex">
                            <td class="font-semibold">Start Date</td>
                            <td>
                                {{ formatDate(data.start_date) }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <vs-button
                v-on:click="
                    $router.push({
                        name: 'brokerEdit',
                        params: { pk: data.email },
                    })
                "
                >Edit Account</vs-button
            >
        </vx-card>
    </div>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { http } from '@/services'

export default {
    name: 'AccountCard',
    props: ['data'],
    mixins: [textFormat],
    data() {
        return {
            show: {
                deleteModal: false,
            },
        }
    },
    methods: {
        closeModal() {
            this.show.deleteModal = false
        },
        confirmAgreementDDEmail() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Send Email?`,
                text: 'Account terms and direct debit email',
                accept: this.sendTermsDDEmail,
                acceptText: 'Send',
            })
        },
        confirmAgreementEmail() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Send Email?`,
                text: 'Account terms email',
                accept: this.sendTermsEmail,
                acceptText: 'Send',
            })
        },
        confirmDDEmail() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Send Email?`,
                text: 'Direct debit instructions email',
                accept: this.sendDDEmail,
                acceptText: 'Send',
            })
        },
        sendTermsDDEmail() {
            http.post('send_broker_terms_and_dd_email', {
                broker_email: this.data.email,
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Email sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: 'Email send error',
                        color: 'danger',
                    })
                })
        },
        sendTermsEmail() {
            http.post('send_broker_terms_email', {
                broker_email: this.data.email,
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Email sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: 'Email send error',
                        color: 'danger',
                    })
                })
        },
        sendDDEmail() {
            http.post('send_broker_direct_debit_email', {
                broker_email: this.data.email,
            })
                .then((response) => {
                    this.$vs.notify({
                        text: 'Email sent',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        text: 'Email send error',
                        color: 'danger',
                    })
                })
        },
    },
}
</script>
