<template>
    <div v-if="this.brokerData.data" id="page-user-view">
        <div class="vx-row">
            <div v-if="brokerData.data" class="vx-col w-full mb-base">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <AccountCard
                            :data="brokerData.data.broker"
                        ></AccountCard>
                    </div>
                </div>
            </div>
        </div>

        <div class="vx-row">
            <div
                class="vx-col w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mb-base"
            >
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <QuickCodesCard
                            :data="brokerData.data.quick_codes"
                        ></QuickCodesCard>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <NotesCard
                            v-if="brokerData.data.broker"
                            type="broker"
                            :notes="brokerData.data.notes"
                            apiUrl="broker_note"
                            :pk="brokerData.data.broker.email"
                            @notesFetch="notesFetch"
                            title="Notes"
                        ></NotesCard>
                    </div>
                </div>
            </div>

            <div
                class="vx-col w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 mb-base"
            >
                <div class="vx-row">
                    <div class="vx-col w-full mb-base">
                        <SendLog
                            v-if="brokerData.data.send_log"
                            :data="brokerData.data.send_log"
                            @getPage="sendLogPageFetch"
                        ></SendLog>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <BrokerInvoicesCard
                            v-if="brokerData.data.invoices"
                            :data="brokerData.data.invoices"
                            :broker="brokerData.data.broker.email"
                            @getPage="invoicesPageFetch"
                        ></BrokerInvoicesCard>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <ClientsCard
                            v-if="brokerData.data.clients"
                            :data="brokerData.data.clients"
                            :broker="brokerData.data.broker.email"
                            @getPage="clientsPageFetch"
                        >
                        </ClientsCard>
                    </div>
                    <div class="vx-col w-full mb-base">
                        <UsersCard :broker="brokerData.data.broker.email" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
]

<script>
import { http, httpGoCardless } from '@/services'

import AccountCard from './Cards/Account'
import NotesCard from '@/components/common/Notes'
import SendLog from './Cards/SendLog'
import BrokerInvoicesCard from './Cards/Invoices'
import QuickCodesCard from './Cards/QuickCodes'
import ClientsCard from './Cards/Clients'
import UsersCard from '@/views/pages/Brokers/Broker/Cards/Users'

export default {
    name: 'BrokerView',
    props: [],
    components: {
        UsersCard,
        AccountCard,
        NotesCard,
        SendLog,
        BrokerInvoicesCard,
        QuickCodesCard,
        ClientsCard,
    },
    created() {
        this.brokerDataFetch(this.$route.params.pk)
    },
    beforeRouteUpdate(to, from, next) {
        next()
        this.brokerDataFetch(this.$route.params.pk)
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    data() {
        return {
            brokerData: {
                data: null,
            },
            backPath: null,
        }
    },
    methods: {
        brokerDataFetch(broker_pk) {
            this.$vs.loading()
            http.get('broker_detail_overview', { params: { pk: broker_pk } })
                .then((response) => {
                    this.brokerData.data = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
        notesFetch() {
            http.get('broker_note', {
                params: { pk: this.brokerData.data.broker.email },
            })
                .then((response) => {
                    this.brokerData.data.notes = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        sendLogPageFetch(pageNumber) {
            var data = {
                params: {
                    broker_pk: this.brokerData.data.broker.email,
                    page_number: pageNumber,
                },
            }
            http.get('broker_send_log', data)
                .then((response) => {
                    this.brokerData.data.send_log = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        invoicesPageFetch(pageNumber) {
            var data = {
                params: {
                    broker_pk: this.brokerData.data.broker.email,
                    page_number: pageNumber,
                },
            }
            http.get('broker_invoices', data)
                .then((response) => {
                    this.brokerData.data.invoices = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        clientsPageFetch(pageNumber) {
            var data = {
                params: {
                    broker_pk: this.brokerData.data.broker.email,
                    page_number: pageNumber,
                },
            }
            http.get('clients_list', data)
                .then((response) => {
                    this.brokerData.data.clients = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: 0.8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }

@media screen and (min-width: 1201px) and (max-width: 1211px),
    only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }

    // #account-manage-buttons {
    //   width: 12rem !important;
    //   flex-direction: column;

    //   > button {
    //     margin-right: 0 !important;
    //     margin-bottom: 1rem;
    //   }
    // }
}
</style>
