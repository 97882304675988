<template>
    <vx-card title="Quick Codes" collapse-action>
        <vs-table :data="data" class="mb-base" stripe>
            <template slot="thead">
                <vs-th> Code </vs-th>
                <vs-th> Discount </vs-th>
                <vs-th> Templates </vs-th>
                <vs-th> Used Count </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        {{ tr.code }}
                    </vs-td>

                    <vs-td>
                        {{ tr.discount }}
                    </vs-td>

                    <vs-td>
                        Email -
                        <span
                            v-if="tr.site_template_email"
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'Email',
                                    params: { pk: tr.site_template_email.id },
                                })
                            "
                            >{{ tr.site_template_email.description }}</span
                        >
                        <span v-else-if="data.email_template"> </span> <br />
                        Sms -
                        <span
                            v-if="tr.site_template_sms"
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'Text',
                                    params: { pk: tr.site_template_sms.id },
                                })
                            "
                            >{{ tr.site_template_sms.description }}</span
                        >
                        <span v-else-if="data.sms_template"> </span>
                    </vs-td>

                    <vs-td>
                        {{ tr.used_count }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

export default {
    name: 'QuickCodesCard',
    props: ['data'],
    mixins: [textFormat, tableHelpers],
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        templatesSms(data) {
            if (data.sms_template) return data.sms_template.description
            else if (data.site_template_sms)
                return data.site_template_sms.description
            return ''
        },
    },
}
</script>

<style></style>
