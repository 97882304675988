<template>
    <div>
        <label for="" class="vs-input--label" :class="{ 'text-white': white }"
            ><span v-if="selected">Broker</span></label
        >

        <v-select
            class="text-white"
            v-model="selected"
            :options="brokers"
            @search="fetchBrokers"
            placeholder="Search Brokers"
        ></v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { http } from '@/services'

export default {
    name: 'BrokersSelect',
    components: {
        vSelect,
    },
    props: ['broker', 'initialBroker', 'reset', 'white'],
    watch: {
        reset(value) {
            if (value) this.selected = null
        },
        selected(value) {
            this.$emit('selectedBroker', value)
        },
        broker(value) {
            this.selected = value
        },
        initialBroker: {
            deep: false,
            immediate: true,
            handler(value) {
                this.selected = value
            },
        },
    },
    data() {
        return {
            brokers: [],
            selected: '',
        }
    },
    methods: {
        fetchBrokers(search, loading) {
            if (search) {
                http.get('brokers_widget/' + search)
                    .then((response) => {
                        this.brokers = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.brokers = []
            }
        },
    },
}
</script>
