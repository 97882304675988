<template>
    <vs-popup title="Upload Batch of Clients" :active.sync="show">
        <div id="div-with-loading" class="vs-con-loading__container">
            <div class="vs-row">
                <div class="vs-col w-1/2 mb-6">
                    <BrokerSelect
                        v-if="alwaysShowBroker || (!data.broker && !broker)"
                        @selectedBroker="setBroker"
                        class="mb-3"
                    ></BrokerSelect>

                    <vs-textarea
                        v-model="data.description"
                        label="Description"
                        class="w-full"
                    ></vs-textarea>

                    <BtnFileSelect
                        @fileChanged="data.file = $event"
                        :file="data.file"
                        :acceptFiles="'.csv'"
                    ></BtnFileSelect>
                </div>
            </div>

            <VuePerfectScrollbar
                ref="chatLogPS"
                class="vs-col w-1/2 mb-6 p-6 max"
                :settings="settings"
                :key="$vs.rtl"
            >
                <p
                    v-if="response && response.clients_added !== null"
                    class="mb-3 text-success"
                >
                    {{ response.clients_added }} Clients Added
                    {{ response.add_errors.length }} Not Added
                </p>
                <div v-if="response && response.add_errors">
                    <p
                        v-for="(error, index) in response.add_errors"
                        :key="index"
                        class="text-sm"
                    >
                        {{ error }}
                    </p>
                </div>
            </VuePerfectScrollbar>

            <div class="vs-row">
                <vs-button
                    v-on:click="upload"
                    color="secondary"
                    class="mr-3"
                    :disabled="!validated"
                    >Confirm</vs-button
                >
                <vs-button v-on:click="close" type="flat">Cancel</vs-button>
            </div>
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import BrokerSelect from '@/components/common/BrokerSelect'
import BtnFileSelect from '@/components/common/BtnFileSelect'

export default {
    name: 'UploadClientsModal',
    props: ['dataShow', 'broker', 'alwaysShowBroker'],
    components: {
        BrokerSelect,
        BtnFileSelect,
        VuePerfectScrollbar,
    },
    watch: {
        broker: {
            immediate: true,
            handler(value) {
                this.data.broker = value
            },
        },
    },
    data() {
        return {
            data: {
                broker: null,
                description: null,
                file: null,
            },
            response: null,
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
        }
    },
    computed: {
        validated() {
            if (this.data.file && this.data.description && this.data.broker)
                return true
            return false
        },
        show: {
            get() {
                return this.dataShow
            },
            set(value) {
                this.$emit('show', value)
            },
        },
    },
    methods: {
        close() {
            this.$emit('close')
        },
        upload() {
            if (this.validated) {
                this.$vs.loading({
                    container: '#div-with-loading',
                    scale: 0.6,
                })
                var formData = new FormData()
                formData.append('description', this.data.description)
                formData.append('file', this.data.file)
                formData.append('broker', this.data.broker)
                http.post('clients_list', formData)
                    .then((response) => {
                        this.$vs.loading.close(
                            '#div-with-loading > .con-vs-loading'
                        )
                        this.$emit('refresh')
                        this.data.description = null
                        this.data.file = null
                        this.response = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.loading.close(
                            '#div-with-loading > .con-vs-loading'
                        )
                    })
            }
        },
        setBroker(value) {
            if (value) this.data.broker = value.value
            else this.data.broker = null
        },
    },
}
</script>

<style scoped>
.max {
    max-height: 300px;
}
</style>
