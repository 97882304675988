<template>
    <vx-card title="Invoices" :subtitle="subTitle" collapse-action>
        <div class="flex flex-row-reverse">
            <vs-button
                class="mb-6 ml-6"
                type="flat"
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                v-on:click="show.createModal = true"
                >Create Invoice</vs-button
            >
        </div>
        <vs-table
            ref="table"
            v-model="selected"
            :data="data.data"
            class="mb-base"
            :sst="true"
            @selected="
                $router.push({ name: 'invoice', params: { pk: $event.id } })
            "
        >
            <template slot="thead">
                <vs-th> Dates </vs-th>
                <vs-th> Description </vs-th>
                <vs-th> Amount </vs-th>
                <vs-th> Approved </vs-th>
                <vs-th> Sent </vs-th>
                <vs-th> Paid </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    :data="tr"
                    :key="index"
                    v-for="(tr, index) in data"
                    :state="!tr.paid ? 'danger' : null"
                >
                    <vs-td>
                        {{ formatDate(tr.from_date) }} -
                        {{ formatDate(tr.to_date) }}
                    </vs-td>

                    <vs-td>
                        {{ tr.description }}
                    </vs-td>

                    <vs-td> £{{ tr.amount }} </vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="tr.approved ? 'CheckIcon' : 'XIcon'"
                            :svg-classes="
                                tr.approved ? 'text-primary' : 'text-danger'
                            "
                        />
                    </vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="tr.sent ? 'CheckIcon' : 'XIcon'"
                            :svg-classes="
                                tr.sent ? 'text-primary' : 'text-danger'
                            "
                        />
                    </vs-td>

                    <vs-td>
                        <feather-icon
                            :icon="tr.paid ? 'CheckIcon' : 'XIcon'"
                            :svg-classes="
                                tr.paid ? 'text-primary' : 'text-danger'
                            "
                        />
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
        <CreateInvoiceModal
            :show="show.createModal"
            :broker="broker"
            @close="show.createModal = false"
            @refresh="getPage"
        ></CreateInvoiceModal>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

import CreateInvoiceModal from '@/views/pages/Invoices/All/Modals/Create'

export default {
    name: 'BrokerInvoicesCard',
    props: ['data', 'broker'],
    mixins: [textFormat, tableHelpers],
    components: {
        CreateInvoiceModal,
    },
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            pageNumber: 1,
            selected: null,
            show: {
                createModal: false,
            },
        }
    },
    computed: {
        subTitle() {
            if (this.data.data.length)
                return (
                    'Last invoice ' +
                    this.formatDate(this.data.data[0].from_date) +
                    ' - ' +
                    this.formatDate(this.data.data[0].to_date) +
                    ' ' +
                    this.data.data[0].description
                )
            return 'No invoices'
        },
    },
    methods: {
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage() {
            this.$emit('getPage', this.pageNumber)
        },
        gotoInvoice(event) {
            this.$router.push({ name: 'invoice', params: { pk: event.id } })
        },
    },
}
</script>

<style></style>
