<template>
    <vx-card title="Send Logs" :subtitle="subTitle" collapse-action>
        <vs-table :data="data.data" class="mb-base">
            <template slot="thead">
                <vs-th> Date/Time </vs-th>
                <vs-th> Client/Batch </vs-th>
                <vs-th> Templates </vs-th>
                <vs-th> Number Sent </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr
                    :key="index"
                    v-for="(tr, index) in data"
                    :state="tr.batch ? 'success' : null"
                >
                    <vs-td>
                        {{ formatDateAndTime(tr.date_added) }}
                    </vs-td>

                    <vs-td>
                        {{ batchOrClient(tr) }}
                    </vs-td>

                    <vs-td>
                        Email - {{ templatesEmail(tr) }} <br />
                        Sms - {{ templatesSms(tr) }}
                    </vs-td>

                    <vs-td>
                        {{ tr.emails_sent }}<br />
                        {{ tr.sms_sent }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

export default {
    name: 'SendLog',
    props: ['data'],
    mixins: [textFormat, tableHelpers],
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            pageNumber: 1,
        }
    },
    computed: {
        subTitle() {
            if (this.data.data.length)
                return (
                    'Last client send ' +
                    this.timeDifference(this.data.data[0].date_added)
                )
            return 'No logs'
        },
    },
    methods: {
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage() {
            this.$emit('getPage', this.pageNumber)
        },
        batchOrClient(data) {
            if (data.client) return data.client.name
            else if (data.batch) return 'Batch : ' + data.batch.description
            return ''
        },
        templatesEmail(data) {
            if (data.custom_email_template)
                return data.custom_email_template.description
            else if (data.default_email_template)
                return data.default_email_template.description
            return ''
        },
        templatesSms(data) {
            if (data.custom_sms_template)
                return data.custom_sms_template.description
            else if (data.default_sms_template)
                return data.default_sms_template.description
            return ''
        },
    },
}
</script>

<style></style>
