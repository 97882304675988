<template>
    <vs-sidebar
        position-right
        parent="body"
        default-index="1"
        color="primary"
        class="add-new-data-sidebar items-no-padding"
        spacer
        v-show="show"
    >
        <div class="mt-6 flex items-center justify-between px-6">
            <h4>{{ title }}</h4>
            <feather-icon
                icon="XIcon"
                @click.stop="$emit('close')"
                class="cursor-pointer"
            ></feather-icon>
        </div>
        <vs-divider class="mb-0"></vs-divider>
        <VuePerfectScrollbar
            class="scroll-area--data-list-add-new"
            :settings="settings"
            :key="$vs.rtl"
        >
            <div class="p-6">
                <slot name="content"></slot>
            </div>
        </VuePerfectScrollbar>
        <div class="flex flex-wrap items-center p-6" slot="footer">
            <slot name="buttons"></slot>
        </div>
    </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'SideBarModal',
    components: {
        VuePerfectScrollbar,
    },
    props: ['title', 'show'],
    data() {
        return {
            settings: {
                // perfectscrollbar settings
                maxScrollbarLength: 60,
                wheelSpeed: 0.6,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }

    ::v-deep .vs-sidebar {
        z-index: 52010;
        width: 400px;
        max-width: 90vw;
    }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
