<template>
    <vx-card title="Users" collapse-action>
        <vs-table
            ref="table"
            :data="users"
            class="mb-base"
            :sst="true"
            @selected="
                $router.push({ name: 'user', params: { id: $event.id } })
            "
        >
            <template slot="thead">
                <vs-th> Email </vs-th>
                <vs-th> Last Login </vs-th>
                <vs-th> Date Joined </vs-th>
                <vs-th> </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        {{ tr.email }}
                    </vs-td>

                    <vs-td>
                        {{ formatDateAndTime(tr.last_login) }}
                    </vs-td>

                    <vs-td>
                        {{ formatDateAndTime(tr.date_joined) }}
                    </vs-td>
                    <vs-td>
                        <vx-tooltip text="Login as broker" color="primary">
                            <feather-icon
                                @click="loginAsUser(tr.id)"
                                icon="LogInIcon"
                            ></feather-icon>
                        </vx-tooltip>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

export default {
    name: 'UsersCard',
    props: ['broker'],
    mixins: [textFormat, tableHelpers],
    components: {},
    mounted() {
        this.getPage()
    },
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            users: [],
        }
    },
    computed: {},
    methods: {
        gotoUser() {
            console.log('goto user')
        },
        getPage() {
            if (!this.broker) return
            http.get('broker_users', {
                params: { broker_pk: this.broker },
            })
                .then((response) => {
                    this.users = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        loginAsUser(user_id) {
            var data = {
                user_pk: user_id,
                action: 'login_as_user',
            }
            http.post('broker_users', data)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.access && response.data.refresh) {
                        var base =
                            process.env.NODE_ENV === 'production'
                                ? 'https://broker.mylastwill.co.uk/login/'
                                : 'http://127.0.0.1:8060/login/'
                        var url =
                            base +
                            response.data.access +
                            '/' +
                            response.data.refresh
                        console.log(url)
                        var win = window.open(url, '_blank')
                        win.focus()
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style></style>
