<template>
    <vx-card title="Clients" :subtitle="subTitle" collapse-action>
        <div class="flex flex-row-reverse">
          <vs-button
              id="button-with-loading"
              class="mb-6 ml-6"
              type="flat"
              color="primary"
              icon-pack="feather"
              icon="icon-download"
              v-on:click="downloadReport"
          >Download Report</vs-button
          >
            <vs-button
                class="mb-6 ml-6"
                type="flat"
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                v-on:click="show.uploadModal = true"
                >Upload Batch</vs-button
            >
        </div>
        <vs-table
            ref="table"
            v-model="selected"
            :data="data.data"
            class="mb-base"
            :sst="true"
        >
            <template slot="thead">
                <vs-th> Name </vs-th>
                <vs-th> Date Added </vs-th>
                <vs-th> </vs-th>
                <vs-th> </vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
                    <vs-td>
                        {{ tr.name }}
                    </vs-td>

                    <vs-td>
                        <vx-tooltip :text="formatDateAndTime(tr.date_added)">
                            {{ timeDiffCat(tr.date_added)[0] }}
                        </vx-tooltip>
                    </vs-td>

                    <vs-td>
                        {{ tr.phone1 }} <br />
                        {{ tr.phone2 }}
                    </vs-td>

                    <vs-td>
                        {{ tr.email1 }} <br />
                        {{ tr.email2 }}
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination
            v-if="data.number_of_pages > 1"
            :total="data.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
        <UploadClientsModal
            :dataShow="show.uploadModal"
            @show="show.uploadModal = $event"
            @close="show.uploadModal = false"
            @refresh="getPage"
            :broker="broker"
        ></UploadClientsModal>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

import UploadClientsModal from '@/views/pages/Brokers/Clients/All/Modals/UploadClients'

export default {
    name: 'ClientsCard',
    props: ['data', 'broker'],
    mixins: [textFormat, tableHelpers],
    components: {
        UploadClientsModal,
    },
    watch: {
        pageNumber(value) {
            if (value) this.getPage()
        },
    },
    data() {
        return {
            pageNumber: 1,
            selected: null,
            show: {
                createModal: false,
                uploadModal: false,
            },
        }
    },
    computed: {
        subTitle() {
            if (this.data.data.length)
                return (
                    'Last client added ' +
                    this.formatDate(this.data.data[0].date_added)
                )
            return 'No clients'
        },
    },
    methods: {
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage() {
            this.$emit('getPage', this.pageNumber)
        },
        gotoInvoice(event) {
            this.$router.push({ name: 'invoice', params: { pk: event.id } })
        },
      downloadReport() {
        this.loadingDownload()
        this.$vs.notify({
          text: `Downloading Client Report for broker ${this.broker}`,
          color: 'success',
        })
        http.post(
            'clients_report',
            { broker: this.broker },
            {
              responseType: 'arraybuffer',
            }
        )
            .then((response) => {
              const filename = `client-report-${this.broker}.csv`
              this.openFile(response, filename)
              this.loadingDownloadClose()
            })
            .catch((error) => {
              this.$vs.notify({
                text: error,
                color: 'danger',
              })
              console.log(error)
              this.loadingDownloadClose()
            })
      },
      openFile(response, filename) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], {
          type: type,
          encoding: 'UTF-8',
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      },
      loadingDownload() {
        this.$vs.loading({
          background: 'primary',
          color: '#fff',
          container: '#button-with-loading',
          scale: 0.45,
        })
      },
      loadingDownloadClose() {
        this.$vs.loading.close('#button-with-loading > .con-vs-loading')
      },
    },
}
</script>

<style></style>
