<template>
    <SideBarModal :title="title" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <div class="vx-row mb-2">
                <div class="vx-col w-full mb-3">
                    <p>Range</p>
                    <DatePicker
                        mode="range"
                        v-model="range"
                        :masks="{ title: 'MMM YYYY' }"
                        locale="gmt"
                    ></DatePicker>

                    <!--
          <div class="flex-auto">
            <label
              for=""
              class="vs-input--label"
            >From</label>

            <datepicker
              placeholder="From Date"
              v-model="fromDate"
            ></datepicker>
          </div>
          -->
                </div>
                <!--
         <div class="vx-col w-full mb-3">
          <div class="flex-auto">
            <label
              for=""
              class="vs-input--label"
            >To</label>

            <datepicker
              placeholder="To Date"
              v-model="toDate"
            ></datepicker>
          </div>
        </div>
        -->

                <div v-if="!broker && !multiple" class="vx-col w-full">
                    <div class="flex-auto">
                        <label for="" class="vs-input--label">Broker</label>
                        <BrokerSelect
                            @selectedBroker="setBroker"
                        ></BrokerSelect>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button class="mr-6" @click="create">Create</vs-button>
            <vs-button type="border" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import SideBarModal from '@/components/common/SideBarModal'
import BrokerSelect from '@/components/common/BrokerSelect'

export default {
    name: 'CreateInvoiceModal',
    components: {
        SideBarModal,
        BrokerSelect,
        DatePicker,
    },
    props: ['show', 'broker', 'multiple'],
    watch: {
        broker: {
            immediate: true,
            handler(value) {
                this.createData.broker = value
            },
        },
    },
    data() {
        return {
            createData: {
                to_date: null,
                from_date: null,
                broker: null,
            },
            range: {
                start: null,
                end: null,
            },
        }
    },
    computed: {
        fromDate: {
            set(value) {
                if (value)
                    this.createData.from_date = value.toISOString().slice(0, 10)
                else this.createData.from_date = null
            },
            get() {
                return this.createData.from_date
            },
        },
        toDate: {
            set(value) {
                if (value)
                    this.createData.to_date = value.toISOString().slice(0, 10)
                else this.createData.to_date = null
            },
            get() {
                return this.createData.to_date
            },
        },
        title() {
            if (this.multiple) return 'Create Invoices *'
            return 'Create Invoice *'
        },
    },
    methods: {
        create() {
            if (
                (this.createData.broker || this.multiple) &&
                this.range.start &&
                this.range.end
            ) {
                var formData = Object.assign({}, this.createData)

                var from = new Date(this.range.start)
                var to = new Date(this.range.end)

                var iso_from = new Date(
                    from.getTime() - from.getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .slice(0, 10)
                var iso_to = new Date(
                    to.getTime() - to.getTimezoneOffset() * 60000
                )
                    .toISOString()
                    .slice(0, 10)

                formData.from_date = iso_from
                formData.to_date = iso_to

                var url = 'invoice_detail'
                if (this.multiple) url = 'create_multiple_invoices'
                http.post(url, formData)
                    .then((response) => {
                        this.$emit('refresh')
                        this.$emit('close')
                        this.$vs.notify({
                            text: 'Invoice created',
                            color: 'success',
                        })
                        this.toDate = null
                        this.fromDate = null
                    })
                    .catch((error) => {
                        console.log(error)
                        this.$vs.notify({
                            text: error.response.data,
                            color: 'danger',
                        })
                    })
            }
        },
        setBroker(value) {
            if (value) this.createData.broker = value.value
            else this.createData.broker = null
        },
    },
}
</script>

<style lang="scss">
.dropdown-button-container {
    display: flex;
    align-items: center;

    .btnx {
        border-radius: 5px 0px 0px 5px;
    }

    .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.con-vs-dropdown--menu {
    z-index: 70000;
}
</style>
