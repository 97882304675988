<template>
    <vx-card
        :title="title"
        :subtitle="cardSubTitle"
        collapse-action
        class="overflow-hidden"
    >
        <template slot="no-body">
            <div class="chat-card-lo">
                <VuePerfectScrollbar
                    ref="chatLogPS"
                    class="scroll-area pt-6 px-6"
                    :settings="settings"
                    :key="$vs.rtl"
                    style="max-height: 500px"
                >
                    <ul ref="chatLog">
                        <li v-for="(note, index) in notes" :key="index">
                            <div class="flex items-start">
                                <div
                                    v-if="edit.id !== note.id"
                                    class="msg block bg-primary shadow-md py-3 px-4 mb-2 mr-3 w-full rounded-lg text-white"
                                >
                                    <vx-tooltip :text="noteLabel(note)">
                                        <span>{{ note.note }}</span>
                                    </vx-tooltip>
                                </div>

                                <vs-textarea
                                    v-else
                                    class="mr-3 w-full"
                                    :label="noteLabel(note)"
                                    v-model="edit.note"
                                />
                                <div class="flex flex-col">
                                    <vs-button
                                        v-if="edit.id === note.id"
                                        type="flat"
                                        radius
                                        v-on:click="saveEdit"
                                        icon-pack="feather"
                                        icon="icon-save"
                                    ></vs-button>
                                    <vs-button
                                        v-else
                                        radius
                                        type="flat"
                                        v-on:click="setEdit(note)"
                                        icon-pack="feather"
                                        icon="icon-edit-2"
                                    ></vs-button>
                                    <vs-button
                                        v-if="edit.id === note.id"
                                        type="flat"
                                        radius
                                        color="secondary"
                                        v-on:click="deleteNote"
                                        icon-pack="feather"
                                        icon="icon-minus"
                                    ></vs-button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </VuePerfectScrollbar>
            </div>
            <div class="flex bg-white chat-input-container p-6">
                <vs-textarea
                    class="mr-3 w-full"
                    placeholder="New note"
                    v-model="newNote.note"
                />
                <vs-button
                    v-on:click="createNote"
                    type="flat"
                    radius
                    icon-pack="feather"
                    icon="icon-plus"
                ></vs-button>
            </div>
        </template>
    </vx-card>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import { http } from '@/services'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    name: 'NotesCard',
    mixins: [textFormat, tableHelpers],
    props: ['notes', 'pk', 'apiUrl', 'type', 'title'],
    components: {
        VuePerfectScrollbar,
    },
    watch: {
        type: {
            immediate: true,
            handler(value) {
                this.newNote[value] = null
            },
        },
        pk: {
            immediate: true,
            handler(value) {
                this.newNote[this.type] = value
            },
        },
    },
    computed: {
        cardSubTitle() {
            if (this.notes !== null && this.notes.length) {
                return (
                    `${this.notes.length} ${
                        this.notes.length > 1 ? 'Notes' : 'Note'
                    } - Last modified ` +
                    this.timeDifference(this.notes[0].updated_date)
                )
            }
            return 'No Notes'
        },
    },
    data() {
        return {
            edit: {
                id: null,
                note: null,
            },
            newNote: {
                broker: null,
                note: null,
            },
            settings: {
                maxScrollbarLength: 80,
                wheelSpeed: 0.6,
            },
        }
    },
    methods: {
        setEdit(value) {
            ;(this.edit.id = value.id), (this.edit.note = value.note)
        },
        noteLabel(value) {
            var string = `Created: ${this.formatDateAndTime(
                value.created_date
            )} \n Modified: ${this.formatDateAndTime(value.updated_date)}`
            if (value.last_edited_by && value.last_edited_by.email)
                return (string += `\n Modified by: ${value.last_edited_by.email}`)
            return string
        },
        saveEdit() {
            if (this.edit.id) {
                http.patch(this.apiUrl, this.edit)
                    .then((response) => {
                        this.$emit('notesFetch', this.userId)
                        this.edit.id = null
                        this.edit.note = null
                        this.$vs.notify({
                            text: 'Note saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        createNote() {
            if (this.newNote.note) {
                http.post(this.apiUrl, this.newNote)
                    .then((response) => {
                        this.$emit('notesFetch', this.userId)
                        this.newNote.note = null
                        this.$vs.notify({
                            text: 'Note created',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        deleteNote() {
            if (this.edit.id) {
                http.delete(this.apiUrl, { params: { id: this.edit.id } })
                    .then((response) => {
                        this.$emit('notesFetch', this.userId)
                        this.edit.id = null
                        this.edit.note = null
                        this.$vs.notify({
                            text: 'Note deleted',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>
